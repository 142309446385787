import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Remember to polyfill position: sticky
// https://github.com/wilddeer/stickyfill

const ClipArea = styled.div`
  height: ${props => props.height};
  overflow: hidden;
  width: ${props => props.width};
`;

const StickyContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 50%;
  transform: translate3d(0, -50%, 0);
`;

const emptyDOMRect =
  typeof DOMRect !== 'undefined'
    ? new DOMRect()
    : {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      };

const DefaultWrapper = ({ children }) => <>{children}</>;

export default ({
  children,
  height = '100%',
  scrollTarget = null,
  style = {},
  width = '100%',
  wrapper: ClipWrapper = DefaultWrapper,
  ...rest
}) => {
  const containerRef = useRef();
  const scrollTargetRef = useRef(scrollTarget);

  const [childrenHeight, setChildrenHeight] = useState(0);
  const [clipOffset, setClipOffset] = useState(0);
  const [wrapperDOMRect, setWrapperDOMRect] = useState(emptyDOMRect);

  const handleScroll = useCallback(() => {
    const { pageYOffset, scrollTop } = scrollTargetRef.current;
    const currentScroll = pageYOffset != null ? pageYOffset : scrollTop;
    const startPoint = containerRef.current.offsetTop + wrapperDOMRect.height / 2 - window.innerHeight / 2;
    const endPoint = childrenHeight - wrapperDOMRect.height;
    const delta = currentScroll - startPoint;
    const newOffset = delta < 0 ? 0 : Math.min(delta, endPoint);

    // console.log(scrollTargetRef.current, scrollTargetRef.current.scrollTop);

    if (newOffset !== clipOffset) {
      setClipOffset(newOffset);
    }
  }, [childrenHeight, clipOffset, containerRef, scrollTargetRef, wrapperDOMRect]);

  const childrenWrapperRef = useCallback(node => {
    node != null && setChildrenHeight(node.getBoundingClientRect().height);
  }, []);

  const wrapperRef = useCallback(node => {
    node != null && setWrapperDOMRect(node.getBoundingClientRect());
  }, []);

  useEffect(() => {
    if (scrollTarget == null) {
      scrollTargetRef.current = window;
    } else {
      scrollTargetRef.current = scrollTarget;
    }

    scrollTargetRef.current.addEventListener('scroll', handleScroll);
    return () => scrollTargetRef.current.removeEventListener('scroll', handleScroll);
  }, [handleScroll, scrollTarget]);

  return (
    <StickyContainer
      ref={containerRef}
      style={Object.assign(style, {
        height: `${childrenHeight}px`,
        paddingTop: `${wrapperDOMRect.height / 2}px`,
      })}
      {...rest}>
      <StickyWrapper ref={wrapperRef} style={{ marginBottom: `calc(-${wrapperDOMRect.height / 2}px` }}>
        <ClipWrapper>
          <ClipArea height={height} width={width}>
            <div
              ref={childrenWrapperRef}
              style={{
                transform: `translate3d(0, -${clipOffset}px, 0)`,
                width: '100%',
                willChange: 'transform',
              }}>
              {children}
            </div>
          </ClipArea>
        </ClipWrapper>
      </StickyWrapper>
    </StickyContainer>
  );
};
