import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import './layout.css';

const H2 = styled.h2`
  font-size: 2em;

  @media (max-width: 600px) {
    font-size: x-large;
  }
`;

const Layout = styled.div`
  background: black;
  color: white;
  position: relative;
  width: 100vw;
`;

const P = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
`;

const Page = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 500px;
  min-height: 100vh;
  padding-top: 200px;

  @media (max-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 36vw;
  }
`;

export { H2, P, Page };

export default ({ children }) => {
  return (
    <Layout>
      <Helmet>
        <link rel="preload" as="style" href="https://use.typekit.net/bgw8vmq.css" crossorigin="true" />
        <link rel="stylesheet" href="https://use.typekit.net/bgw8vmq.css" />
      </Helmet>
      <>{children}</>
    </Layout>
  );
};
